import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import * as Logos from '../Logos';

export interface SkillProps {
  name: string;
  proficiency: 'low' | 'medium' | 'moderate' | 'high';
  url: string;
  icon:
    | 'AWS'
    | 'Docker'
    | 'Electron'
    | 'Elixir'
    | 'GraphQL'
    | 'Nodejs'
    | 'Python'
    | 'ReactLogo';
}

const proficiencyMap = {
  low: 25,
  medium: 50,
  moderate: 75,
  high: 100,
};

const Skill: React.FC<SkillProps> = ({ name, proficiency, url, icon }) => {
  const Icon = Logos[icon];
  return (
    <motion.div
      className="Skills__skill"
      variants={{
        start: { x: '100%', opacity: 0 },
        enter: { x: '0%', opacity: 1 },
        exit: { x: '-100%', opacity: 0 },
      }}
      initial="start"
      animate="enter"
      exit="exit"
      transition={{
        delayChildren: 1,
        damping: 300,
      }}
    >
      <div className="Skills__asset">
        <AnimatePresence exitBeforeEnter>
          <motion.a
            key={name}
            variants={{ start: { opacity: 0 }, enter: { opacity: 1 } }}
            initial="start"
            animate="enter"
            exit="start"
            href={url}
            target="_blank"
            aria-label={name}
            tabIndex={-1}
          >
            <Icon />
            <span>{name}</span>
          </motion.a>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default Skill;
