import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import useMeasure from 'use-measure';

import './Dots.css';

interface DotsProps {
  align?: 'left top' | 'left bottom' | 'right top' | 'right bottom';
  widthRatio?: number;
  heightRatio?: number;
  size?: number;
}

const Dots: React.FC<DotsProps> = ({
  align = 'left top',
  size = 6,
  widthRatio = 1,
  heightRatio = 1,
}) => {
  const classes = cn('Dots', `-${align}`);
  const [data, setData] = useState<{ rows: any[]; columns: any[] }>({
    rows: [],
    columns: [],
  });
  const nodeRef = useRef<HTMLDivElement>(null);
  const { width, height } = useMeasure(nodeRef);
  useEffect(() => {
    setData({
      rows: [...Array(Math.floor((height * heightRatio) / (size * 2)))],
      columns: [...Array(Math.floor((width * widthRatio) / (size * 2)))],
    });
  }, [width, height]);

  const [x, y] = align.split(' ');

  const widthOffset = x === 'right' ? width - width * widthRatio : 0;
  const heightOffset = y === 'bottom' ? height - height * heightRatio : 0;

  return (
    <div className={classes} ref={nodeRef}>
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        {data.rows.reduce((memo, row, rowIndex) => {
          data.columns.forEach((column, columnIndex) => {
            memo.push(
              <circle
                key={`${rowIndex}-${columnIndex}`}
                cx={columnIndex * size * 2 + size + widthOffset}
                cy={rowIndex * size * 2 + size + heightOffset}
                r={size / 2}
                fill="rgb(255,255,255,0.2)"
              />
            );
          });
          return memo;
        }, [])}
      </svg>
    </div>
  );
};

export default Dots;
