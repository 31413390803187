import React, { useContext } from 'react';
import cn from 'classnames';
import {
  motion,
  AnimationProps,
  MotionProps,
  AnimatePresence,
} from 'framer-motion';
import { FluidObject } from 'gatsby-image';

import { Context } from './Context';
import Button from '../Button';
import Dots from '../Shapes/Dots';

import './Item.css';

export interface Project {
  title: string;
  year: string;
  code: string;
  slug: string;
  image: FluidObject;
  embed?: string;
  explore?: string;
  resources: string[];
}

interface ItemProps extends AnimationProps, MotionProps {
  project: Project;
  variant: 'active' | 'prev' | 'next';
  onClick: () => void;
}

const Item: React.FC<ItemProps> = ({ project, variant, ...props }) => {
  const {
    state: { size, width },
    dispatch,
  } = useContext(Context);
  const classes = cn('Item', `-${variant}`);
  const isActive = variant === 'active';

  const openModal = () => dispatch({ type: 'SET_MODAL', modal: true });

  return (
    <motion.div className={classes} {...props}>
      <motion.div
        className="Item__content"
        initial={isActive ? 'active' : 'inactive'}
        animate={isActive ? 'active' : 'inactive'}
        variants={{
          inactive: { width: '0%' },
          active: { width: '100%' },
        }}
        transition={{
          type: 'spring',
          delay: 0.1,
          stiffness: isActive ? 50 : 30,
          damping: isActive ? 250 : 200,
        }}
      >
        <div className="Item__code">
          {size === 'large' || (size === 'medium' && isActive) ? (
            <Dots
              widthRatio={0.8}
              heightRatio={0.5}
              align={isActive ? 'right bottom' : 'left bottom'}
            />
          ) : null}
          <h2 className="Item__heading">{project.code}</h2>
        </div>
        <span
          className="Item__title"
          style={{
            marginTop: size === 'small' ? width * 0.6 * 1.379 - 128 - 100 : 0,
          }}
        >
          {project.title}
        </span>
        <AnimatePresence>
          {isActive && (
            <>
              <motion.div
                className="Item__resources"
                variants={{
                  active: {
                    opacity: 1,
                    transition: { delay: 0.7 },
                  },
                  inactive: { opacity: 0 },
                }}
                animate="active"
                initial="inactive"
                exit="inactive"
              >
                <p>{project.resources.join(', ').concat('.')}</p>
                <p>{project.year}</p>
              </motion.div>
              <motion.div
                className="Item__button"
                variants={{
                  enter: { opacity: 1, y: '0%', transition: { delay: 0.7 } },
                  start: { opacity: 0, y: '50%' },
                  exit: { opacity: 0 },
                }}
                animate="enter"
                initial="start"
                exit="exit"
              >
                {!!project.explore && (
                  <Button href={project.explore} size="large">
                    Explore
                  </Button>
                )}
                {!!project.embed && (
                  <Button onClick={() => openModal()} size="large">
                    View
                  </Button>
                )}
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Item;
