import React, { useMemo, useEffect, useState, useRef } from 'react';

import Letter from './Letter';
import './Writer.css';

interface WriterProps {
  children: string;
}

const Writer: React.FC<WriterProps> = ({ children }) => {
  const sentenceArray = useMemo(() => children.split(''), [children]);

  return (
    <div className="Writer">
      {sentenceArray.map((char, index) => (
        <Letter key={index}>{char}</Letter>
      ))}
    </div>
  );
};

export default Writer;
