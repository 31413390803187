import React from 'react';

const TypeScript = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M49 5a6 6 0 016 6v38a6 6 0 01-6 6H11a6 6 0 01-6-6V11a6 6 0 016-6h38zm-4.536 23.242c-1.162 0-2.262.125-3.301.375-1.04.249-1.954.639-2.744 1.168a5.84 5.84 0 00-1.872 2.024c-.458.82-.688 1.8-.688 2.941 0 1.457.42 2.7 1.261 3.728.84 1.029 2.117 1.9 3.829 2.612.672.275 1.299.545 1.88.81.58.265 1.082.54 1.505.825.423.285.757.596 1.001.932.245.336.367.718.367 1.146 0 .316-.076.608-.23.878-.152.27-.384.504-.695.703-.31.199-.698.354-1.161.466a6.98 6.98 0 01-1.628.168 9.396 9.396 0 01-6.037-2.23v4.888c.795.408 1.735.713 2.82.917 1.085.204 2.229.305 3.431.305 1.172 0 2.285-.112 3.34-.336 1.054-.224 1.979-.593 2.774-1.107a5.643 5.643 0 001.887-2.017c.464-.83.695-1.856.695-3.078 0-.886-.132-1.663-.397-2.33a5.445 5.445 0 00-1.146-1.78 8.393 8.393 0 00-1.796-1.398 19.237 19.237 0 00-2.361-1.168 29.74 29.74 0 01-1.727-.772 8.77 8.77 0 01-1.3-.764 3.33 3.33 0 01-.825-.825 1.736 1.736 0 01-.29-.985c0-.336.086-.64.26-.91.173-.27.417-.5.733-.694a3.923 3.923 0 011.162-.451c.458-.107.968-.16 1.528-.16a10.222 10.222 0 012.66.374c.458.127.904.288 1.337.481a7.37 7.37 0 011.2.672v-4.568a11.935 11.935 0 00-2.438-.634c-.881-.137-1.893-.206-3.034-.206zm-10.46.488h-17.48v4.012h6.24v17.863h4.968V32.742h6.272V28.73z"
      fillRule="nonzero"
    />
  </svg>
);

export default TypeScript;
