import React from 'react';
import './Default.css';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { SocialLink } from '../components/SocialLinks';

interface Props {
  children: React.ReactNode;
  socialLinks: SocialLink[];
}

const Layout: React.FC<Props> = ({ socialLinks, children }) => (
  <>
    <Navigation />
    {children}
    <Footer links={socialLinks} />
  </>
);

export default Layout;
