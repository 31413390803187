import React from 'react';

const Nodejs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M28.217 5.533c1.214-.71 2.796-.712 4.01 0 6.104 3.522 12.21 7.037 18.313 10.56 1.148.661 1.916 1.965 1.904 3.316v21.185c.009 1.406-.835 2.742-2.046 3.387-6.084 3.502-12.165 7.008-18.248 10.51-1.24.723-2.853.668-4.054-.115-1.824-1.08-3.652-2.154-5.476-3.232-.373-.227-.793-.408-1.056-.777.233-.32.649-.36.987-.5.761-.247 1.46-.644 2.16-1.029.177-.123.393-.076.562.035 1.56.913 3.106 1.852 4.671 2.757.334.196.672-.065.957-.227 5.971-3.445 11.949-6.877 17.918-10.323a.608.608 0 00.326-.596c.004-6.988 0-13.978.002-20.967a.657.657 0 00-.383-.653C42.701 15.378 36.641 11.887 30.58 8.4a.621.621 0 00-.714-.001c-6.062 3.488-12.12 6.982-18.183 10.468a.65.65 0 00-.384.65v20.968a.595.595 0 00.33.589c1.617.936 3.237 1.866 4.856 2.8.911.5 2.031.798 3.036.414.887-.324 1.508-1.248 1.491-2.21.008-6.947-.004-13.896.006-20.843-.022-.308.265-.563.559-.534.777-.005 1.555-.01 2.332.002.325-.007.548.325.508.636-.003 6.991.008 13.983-.005 20.975.002 1.864-.748 3.891-2.436 4.803-2.08 1.1-4.651.867-6.706-.188-1.779-.906-3.476-1.976-5.224-2.947C8.831 43.34 7.992 41.999 8 40.594V19.41c-.013-1.379.786-2.707 1.97-3.357 6.083-3.505 12.165-7.012 18.247-10.519z"
      fillRule="nonzero"
    />
    <path
      d="M33.729 20.81c2.602-.175 5.387-.104 7.729 1.236 1.813 1.026 2.818 3.18 2.85 5.284-.05.283-.335.44-.594.42-.755 0-1.51.011-2.265-.005-.32.013-.507-.295-.547-.591-.217-1.007-.742-2.004-1.65-2.49-1.392-.728-3.006-.691-4.524-.676-1.109.061-2.3.162-3.24.843-.72.515-.94 1.557-.682 2.395.243.602.908.797 1.452.976 3.136.856 6.459.771 9.535 1.899 1.273.46 2.519 1.353 2.955 2.746.57 1.867.32 4.098-.951 5.597-1.031 1.234-2.533 1.905-4.03 2.27-1.993.464-4.061.475-6.084.27-1.903-.227-3.883-.75-5.352-2.104-1.256-1.14-1.87-2.914-1.808-4.628.014-.29.29-.491.555-.467.76-.007 1.52-.009 2.281 0 .304-.022.529.252.544.551.14.96.486 1.966 1.286 2.535 1.545 1.041 3.484.97 5.253 1 1.466-.069 3.111-.09 4.307-1.101.632-.578.819-1.543.648-2.374-.185-.702-.887-1.029-1.49-1.242-3.096-1.024-6.456-.652-9.521-1.81-1.245-.459-2.448-1.327-2.927-2.663-.667-1.89-.361-4.23 1.044-5.677 1.37-1.44 3.346-1.996 5.226-2.194z"
      fillRule="nonzero"
    />
  </svg>
);

export default Nodejs;
