import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { BiMenu, BiX } from 'react-icons/bi';

interface MenuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  active,
  className,
  children,
  ...props
}) => {
  const classes = cn('Navigation__button', className);
  return (
    <button className={classes} {...props}>
      <AnimatePresence>
        <motion.span
          key={active ? 'close' : 'open'}
          initial={{ x: '100%' }}
          animate={{ x: '0%' }}
          exit={{
            x: '-100%',
          }}
        >
          {active ? <BiX /> : <BiMenu />}
        </motion.span>
      </AnimatePresence>
    </button>
  );
};

export default MenuButton;
