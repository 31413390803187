import React from 'react';

const GraphQL = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M12.141 43.094l-1.833-1.058 20.427-35.38 1.834 1.058z" />
    <path d="M9.888 39.209h40.855v2.117H9.888z" />
    <path d="M31.131 52.197L10.696 40.4l1.059-1.834L32.19 50.364z" />
    <path d="M48.883 21.447L28.448 9.65l1.059-1.834 20.435 11.799z" />
    <path d="M11.76 21.44l-1.058-1.834L31.137 7.807l1.058 1.834z" />
    <path d="M48.506 43.095L28.08 7.715l1.834-1.059 20.427 35.38z" />
    <path d="M11.508 18.202h2.117v23.597h-2.117z" />
    <path d="M47.018 18.202h2.117v23.597h-2.117z" />
    <path d="M30.77 51.3l-.924-1.6 17.773-10.262.925 1.601z" />
    <path d="M51.941 42.487c-1.224 2.13-3.954 2.857-6.084 1.633-2.13-1.225-2.857-3.954-1.633-6.084 1.225-2.13 3.955-2.857 6.085-1.633a4.44 4.44 0 011.632 6.084" />
    <path d="M16.406 21.964c-1.225 2.13-3.954 2.857-6.085 1.633-2.13-1.225-2.857-3.954-1.632-6.084 1.224-2.13 3.954-2.857 6.084-1.633a4.461 4.461 0 011.633 6.084" />
    <path d="M8.702 42.487a4.461 4.461 0 011.632-6.084 4.461 4.461 0 016.084 1.633 4.461 4.461 0 01-1.632 6.084c-2.143 1.224-4.86.497-6.084-1.633" />
    <path d="M44.237 21.964a4.461 4.461 0 011.633-6.084 4.461 4.461 0 016.084 1.633 4.461 4.461 0 01-1.633 6.084c-2.13 1.224-4.86.497-6.084-1.633" />
    <path d="M30.321 54.974a4.447 4.447 0 01-4.451-4.451 4.447 4.447 0 014.451-4.452 4.447 4.447 0 014.452 4.452 4.456 4.456 0 01-4.452 4.451" />
    <path d="M30.321 13.929a4.447 4.447 0 01-4.451-4.452 4.447 4.447 0 014.451-4.451 4.447 4.447 0 014.452 4.451 4.447 4.447 0 01-4.452 4.452" />
  </svg>
);

export default GraphQL;
