import React from 'react';

const Webpack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M51.28 43.952L30.385 55.774v-9.206l13.02-7.164 7.877 4.548zm1.432-1.294v-24.72l-7.644 4.418v15.89l7.644 4.412zM7.651 43.952l20.897 11.822v-9.206L15.52 39.404l-7.87 4.548zm-1.432-1.294v-24.72l7.644 4.418v15.89l-7.644 4.412zm.897-26.322L28.548 4.212v8.898l-13.733 7.554-.11.062-7.589-4.39zm44.7 0L30.383 4.212v8.898l13.732 7.548.11.061 7.59-4.383z" />
    <path d="M28.548 44.473l-12.85-7.069V23.411l12.85 7.418v13.644zm1.836 0l12.849-7.062v-14l-12.85 7.418v13.644zM16.568 21.795l12.898-7.09 12.897 7.09-12.897 7.445-12.898-7.445z" />
  </svg>
);

export default Webpack;
