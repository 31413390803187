import { graphql } from 'gatsby';
import React from 'react';
import { FluidObject } from 'gatsby-image';

import About from '../components/About';
import Hero from '../components/Hero';
import Showcase from '../components/Showcase';
import Contact from '../components/Contact';
import Layout from '../layouts/Default';

interface IndexProps {
  data: {
    about: {
      frontmatter: {
        heading: string;
        description: string;
        socialLinks: {
          icon: 'github' | 'linkedin';
          label: string;
          url: string;
        }[];
      };
    };
    projects: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            year: string;
            code: string;
            slug: string;
            embed?: string;
            explore?: string;
            image: { childImageSharp: { fluid: FluidObject } };
            resources: string[];
          };
        };
      }[];
    };
    skills: {
      edges: {
        node: {
          frontmatter: {
            name: string;
            proficiency: 'low' | 'medium' | 'moderate' | 'high';
            url: string;
            icon:
              | 'AWS'
              | 'Docker'
              | 'Electron'
              | 'Elixir'
              | 'GraphQL'
              | 'Nodejs'
              | 'Python'
              | 'ReactLogo';
          };
        };
      }[];
    };
  };
}

const IndexPage: React.FC<IndexProps> = ({ data }) => (
  <Layout socialLinks={data.about.frontmatter.socialLinks}>
    <Hero />
    <About
      id="about"
      {...data.about.frontmatter}
      skills={data.skills.edges.map((edge) => edge.node.frontmatter)}
    />
    <Showcase
      id="work"
      projects={data.projects.edges.map((edge) => ({
        ...edge.node.frontmatter,
        image: edge.node.frontmatter.image.childImageSharp.fluid,
      }))}
    />
    <Contact />
  </Layout>
);

export const query = graphql`
  {
    about: markdownRemark(
      fileAbsolutePath: { glob: "**/data/about/about.md" } # frontmatter: {}
    ) {
      ...About
    }
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___year] }
      filter: { fileAbsolutePath: { glob: "**/data/projects/*.md" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            year
            code
            slug
            embed
            explore
            resources
            image {
              childImageSharp {
                fluid(maxWidth: 580) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    skills: allMarkdownRemark(
      # sort: { order: DESC, fields: [frontmatter___year] }
      filter: { fileAbsolutePath: { glob: "**/data/skills/*.md" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            proficiency
            url
            icon
          }
        }
      }
    }
  }
`;

export default IndexPage;
