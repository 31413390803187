import React, { useEffect, useRef, useMemo, useContext, useState } from 'react';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import useMeasure from 'use-measure';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import ReactPlayer from 'react-player';

import Anchor from '../Anchor';
import Track from './Track';
import Asset from './Asset';
import { Project } from './Item';
import { Context } from './Context';
import Button from '../Button';
import Diagonal from '../Shapes/Diagonal';
import Modal from '../Modal';

import './Showcase.css';
import { motion, AnimatePresence } from 'framer-motion';

export interface ShowcaseProps {
  id?: string;
  projects: Project[];
}

const Showcase: React.FC<ShowcaseProps> = ({ id, projects }) => {
  const {
    state: { activeIndex, size, modal },
    dispatch,
  } = useContext(Context);
  const [ref, inView] = useInView({ threshold: 0.5 });
  const nodeRef = useRef<HTMLDivElement>(null);
  const [hasBeenViewed, setHasBeenViewed] = useState(inView);

  const { width } = useMeasure(nodeRef);
  const height = useMemo(() => {
    if (size === 'large') {
      return Math.max(420, width * 0.35);
    }

    if (size === 'medium') {
      return Math.max(420, width * 0.55);
    }
    return width * 1.2 + 30;
  }, [width, size]);
  const classes = cn('Showcase', `-${size}`);

  useEffect(() => {
    if (inView) {
      setHasBeenViewed(true);
    }
  }, [inView]);

  useEffect(() => {
    dispatch({ type: 'SET_STAGE_DIMENSIONS', width });
  }, [width]);

  const setActiveIndex = (value: number) => {
    const newIndex =
      activeIndex + value < 0
        ? projects.length + value
        : activeIndex + value < projects.length
        ? activeIndex + value
        : 0;
    dispatch({
      type: 'SET_ACTIVE_ITEM',
      activeIndex: newIndex,
      direction: value,
    });
  };

  const closeModal = () => dispatch({ type: 'SET_MODAL', modal: false });

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight': {
          return setActiveIndex(1);
        }
        case 'ArrowLeft': {
          return setActiveIndex(-1);
        }
        case 'Escape': {
          if (modal) {
            closeModal();
          }
        }
      }
    };
    if (inView) {
      document.body.addEventListener('keyup', onKeyPress);
    }
    return () => document.body.removeEventListener('keyup', onKeyPress);
  }, [inView, activeIndex, modal]);

  const controls = useMemo(() => {
    const buttons = [];
    if (size === 'large') {
      buttons.push({ label: '<', component: <BiChevronLeft />, value: -1 });
    }

    buttons.push({ label: '>', component: <BiChevronRight />, value: 1 });

    return buttons;
  }, [size]);

  return (
    <div className={classes} id={id}>
      <div className="Showcase__anchor Container">
        <Anchor label="FEATURED WORK" />
      </div>
      <div
        className="Showcase__wrapper"
        ref={ref}
        style={{ height: height + 13 * 16 }}
      >
        <div className="Showcase__container Container" ref={nodeRef}>
          {width ? (
            <>
              <motion.div
                className="Showcase__stage"
                initial={{ height: 0 }}
                animate={{ height: hasBeenViewed ? height : 0 }}
                transition={{
                  delay: 0.6,
                  delayChildren: 1.3,
                  type: 'spring',
                  stiffness: 100,
                  damping: 300,
                  mass: 2,
                }}
              >
                <motion.div
                  className="Showcase__underlay"
                  initial="close"
                  animate={hasBeenViewed ? size : 'close'}
                  variants={{
                    close: { width: '100%', left: '0%' },
                    large: { width: '50%', left: '25%' },
                    medium: { width: '75%', left: '0%' },
                    small: { width: '100%', left: '0%' },
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 30,
                    damping: 200,
                  }}
                >
                  <AnimatePresence>
                    {size !== 'small' && (
                      <motion.div
                        className="Showcase__diagonal"
                        initial={{ y: '100%' }}
                        animate={{ y: '0%' }}
                        exit={{ y: '100%' }}
                        transition={{
                          type: 'spring',
                          stiffness: 30,
                          damping: 200,
                        }}
                      >
                        <Diagonal />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
                <Track
                  projects={projects}
                  inView={hasBeenViewed}
                  setActiveIndex={setActiveIndex}
                />
              </motion.div>
              <div className="Showcase__controls">
                <AnimatePresence>
                  {hasBeenViewed
                    ? controls.map((control) => (
                        <motion.div
                          key={control.label}
                          initial={{
                            x: control.value > 0 ? '300%' : '-300%',
                          }}
                          animate={{ x: '0%', transition: { delay: 2 } }}
                          exit={{ x: control.value > 0 ? '300%' : '-300%' }}
                          transition={{
                            type: 'spring',
                            stiffness: 30,
                            damping: 200,
                          }}
                        >
                          <Button
                            onClick={() => setActiveIndex(control.value)}
                            variant="secondary"
                            icon={control.component}
                          />
                        </motion.div>
                      ))
                    : null}
                </AnimatePresence>
              </div>
              <Asset
                asset={projects[activeIndex].image}
                inView={hasBeenViewed}
              />
            </>
          ) : null}
        </div>
      </div>
      <Modal isOpen={modal} onBackdropClick={closeModal}>
        {!!projects[activeIndex].embed && (
          <div className="Showcase__modal">
            <ReactPlayer
              url={projects[activeIndex].embed || ''}
              width="100%"
              height="100%"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Showcase;

export const query = graphql`
  fragment ShowcasePanel on MarkdownRemark {
    frontmatter {
      title
      year
      code
      slug
      resources
    }
  }
`;
