import React from 'react';
import { graphql } from 'gatsby';

import Anchor from '../Anchor';
import Card from '../Card';
import Skills from '../Skills';
import { SkillProps } from '../Skills/Skill';
import SocialLinks, { SocialLink } from '../SocialLinks';

import packageJson from '../../../package.json';

import './About.css';

export interface AboutProps {
  id?: string;
  heading: string;
  description: string;
  socialLinks: SocialLink[];
  skills: SkillProps[];
}

const About: React.FC<AboutProps> = ({
  id,
  heading,
  description,
  socialLinks,
  skills,
}) => (
  <div className="About" id={id}>
    <div className="Container">
      <div className="About__container">
        <Anchor label={`BUILD V${packageJson.version}`} />
        <Card className="About__card" color="red" border>
          <div className="About__content">
            <h2>{heading}</h2>
            <p>{description}</p>
            <SocialLinks links={socialLinks} />
          </div>
          <div className="About__carousel">
            <Skills skills={skills} />
          </div>
        </Card>
      </div>
    </div>
  </div>
);

export const query = graphql`
  fragment About on MarkdownRemark {
    frontmatter {
      heading
      description
      socialLinks {
        icon
        label
        url
      }
    }
  }
`;

export default About;
