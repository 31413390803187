import React, { useMemo, useContext } from 'react';

import { AnimatePresence } from 'framer-motion';

import Item, { Project } from './Item';
import { Context } from './Context';

import './Track.css';

interface TrackProps {
  projects: Project[];
  inView: boolean;
  setActiveIndex: (value: number) => void;
}

const Track: React.FC<TrackProps> = ({ projects, inView, setActiveIndex }) => {
  const {
    state: { activeIndex, size, width },
  } = useContext(Context);

  const visibleProjects = useMemo(() => {
    const panels: Project[] = [];

    // if show previous
    if (size === 'large') {
      const previousIndex =
        activeIndex - 1 >= 0 ? activeIndex - 1 : projects.length - 1;
      panels.push(projects[previousIndex]);
    }

    panels.push(projects[activeIndex]);

    if (size !== 'small') {
      const nextIndex = activeIndex + 1 < projects.length ? activeIndex + 1 : 0;
      panels.push(projects[nextIndex]);
    }

    return panels;
  }, [projects, activeIndex, size]);

  const getVariant = (index: number) => {
    if (size === 'large' && index === 0) {
      return 'prev';
    }

    if (
      (size === 'large' && index === 2) ||
      (size === 'medium' && index === 1)
    ) {
      return 'next';
    }

    return 'active';
  };

  const initialPosition = {
    small: {
      prev: {},
      active: { x: width, width: '100%', originX: 0 },
      next: {},
    },
    medium: {
      prev: {},
      active: { x: width * -0.25, width: '20%', originX: 0 },
      next: { x: width + width * 0.25, width: '20%', originX: 0 },
    },
    large: {
      prev: { x: width * -0.25, width: '20%', originX: 0 },
      active: {
        x: width * 0.55,
        y: '50%',
        opacity: 0,
        width: '20%',
        originX: 0,
      },
      next: { x: width + width * 0.25, width: '20%', originX: 0 },
    },
  };

  const positionMarker = {
    small: {
      prev: {},
      active: { x: 0, originX: 0, width: '100%' },
      next: {},
    },
    medium: {
      prev: {},
      active: { x: width * 0.49, width: '25%', originX: 0 },
      next: { x: width * 0.8, width: '20%', originX: 0 },
    },
    large: {
      prev: { x: 0, width: '20%', originX: 0 },
      active: {
        x: width * 0.55,
        width: '20%',
        y: '0%',
        opacity: 1,
        originX: 0,
      },
      next: { x: width * 0.8, width: '20%', originX: 0 },
    },
  };

  return (
    <div className="Track">
      <div className="Track__inner">
        <AnimatePresence custom={size}>
          {visibleProjects.map((project, index) => {
            const variant = getVariant(index);
            const value = variant === 'prev' ? -1 : variant === 'next' ? 1 : 0;
            return (
              <Item
                key={project.slug}
                onClick={() => setActiveIndex(value)}
                project={project}
                variant={variant}
                initial={initialPosition[size][variant]}
                animate={
                  inView
                    ? positionMarker[size][variant]
                    : initialPosition[size][variant]
                }
                exit={{
                  x: ['prev', 'active'].includes(variant)
                    ? size === 'small'
                      ? -width
                      : size === 'medium'
                      ? width * -0.5
                      : width * -0.25
                    : width + width * 0.25,
                  transition: {
                    type: 'spring',
                    damping: 300,
                  },
                }}
                transition={{
                  type: 'spring',
                  stiffness: 30,
                  damping: 300,
                }}
              />
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Track;
