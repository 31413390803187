import React from 'react';

import Card from '../Card';
import Diagonal from '../Shapes/Diagonal';
import SocialLinks, { SocialLink } from '../SocialLinks';
import './Footer.css';

interface FooterProps {
  links: SocialLink[];
}

const Footer: React.FC<FooterProps> = ({ links }) => (
  <div className="Footer">
    <div className="Container">
      <Card className="Footer__container" border={false}>
        <Diagonal />
        <SocialLinks links={links} />
      </Card>
    </div>
  </div>
);

export default Footer;
