import React, { createRef, useEffect, useState, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import './Hero.css';
import Writer from '../Writer';
import Jehuty from './jehuty.json';

const salutations = [
  `CR8T1V3 T3CHN0`,
  `FULLSTACK ALAKING`,
  `IOT TINKERING`,
  `PRO GOOGLER`,
  `HOWZIT MY\u00a0BRU`,
  `HOWZIT MY\u00a0CHARNA`,
  `HOWZIT MY\u00a0LARNEY`,
];

const getSalutation = () =>
  salutations[Math.floor(Math.random() * salutations.length)];

const Hero = () => {
  const container = createRef<HTMLDivElement>();
  const [salute, setSalute] = useState(salutations[0]);
  const request = useRef<number>(0);
  const lastUpdate = useRef<number>();

  const update: FrameRequestCallback = (time) => {
    if (!lastUpdate.current) {
      lastUpdate.current = time;
    }
    if (time - lastUpdate.current > 5000) {
      setSalute(getSalutation());
      lastUpdate.current = time;
    }
    request.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    request.current = requestAnimationFrame(update);
    () => cancelAnimationFrame(request.current);
  }, []);

  useEffect(() => {
    let animation: AnimationItem;
    if (container.current) {
      animation = lottie.loadAnimation({
        container: container.current!,
        renderer: 'svg',
        autoplay: true,
        animationData: Jehuty,
        loop: false,
      });
    }

    return () => {
      animation?.destroy();
    };
  }, []);

  return (
    <div className="Hero">
      <div className="Hero__container Container">
        <div className="Hero__content">
          <div className="Hero__text">
            {salute.split(' ').map((line, index) => (
              <Writer key={index}>{line}</Writer>
            ))}
          </div>
        </div>
        <div key="done" ref={container} className="Hero__asset" />
      </div>
    </div>
  );
};

export default Hero;
