import React, { useState } from 'react';
import ScrollLock from 'react-scrolllock';
import { Link } from 'gatsby';
import cn from 'classnames';

import './Navigation.css';
import NavItem from './NavItem';
import NavMenu from './NavMenu';

interface NavigationProps {
  links: {
    label: string;
    url: string;
  }[];
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const [open, setOpen] = useState(false);
  const classes = cn('Navigation', { '-open': open });

  const toggleMenu = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setOpen(!open);
  };

  const maybeCloseMenu = () => {
    if (open) {
      toggleMenu();
    }
  };

  return (
    <header className={classes}>
      <ScrollLock isActive={open} />
      <nav>
        <Link className="Navigation__logo" to="/">
          Cuzzy
          <span data-letters="Cuzzy" />
          <span data-letters="Cuzzy" />
        </Link>
        <div className="Navigation__mobile">
          <NavMenu active={open} onClick={() => toggleMenu()} />
        </div>
        <div className="Navigation__menu">
          <ul className="Navigation__links">
            {props.links.map((item, idx) => (
              <NavItem key={idx} {...item} onClick={() => maybeCloseMenu()} />
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
