import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import ScrollLock from 'react-scrolllock';

import Card from '../Card';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  onBackdropClick: () => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onBackdropClick,
  ...props
}) => {
  const portalNode = useRef<HTMLDivElement>();
  useEffect(() => {
    portalNode.current = document.createElement('div');
    portalNode.current.className = 'ModalPortal';

    document.body.appendChild(portalNode.current);

    return () => {
      if (portalNode.current) {
        document.body.removeChild(portalNode.current);
      }
    };
  }, []);

  if (!portalNode.current) {
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="Modal"
          variants={{
            open: { opacity: 1 },
            close: { opacity: 0 },
          }}
          initial="close"
          animate="open"
          exit="close"
          transition={{
            delayChildren: 0.4,
          }}
          onClick={onBackdropClick}
        >
          <motion.div
            className="Modal__content"
            variants={{
              open: { opacity: 1, y: '0%' },
              close: { opacity: 0, y: '50%' },
            }}
          >
            <ScrollLock isActive={isOpen} />
            <Card color="purple">{children}</Card>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    portalNode.current
  );
};

export default Modal;
