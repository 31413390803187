import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Img, { FluidObject } from 'gatsby-image';

import { Context } from './Context';
import './Asset.css';

interface AssetProps {
  asset: FluidObject;
  inView: boolean;
}

const getWidth = (size: string, width: number) => {
  if (size === 'large') {
    return width * 0.25;
  }

  if (size === 'medium') {
    return width * 0.4;
  }

  return width * 0.6;
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
    };
  },
  center: {
    zIndex: 1,
    x: '0%',
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
    };
  },
};

const Asset: React.FC<AssetProps> = ({ asset, inView }) => {
  const {
    state: { direction, size, width },
  } = useContext(Context);

  const assetWidth = getWidth(size, width);
  const height = assetWidth * 1.379;

  return (
    <div className="Asset">
      <motion.div
        className="Asset__border"
        initial={{ height: 0 }}
        animate={{ height: inView ? height : 0 }}
        transition={{
          duration: 0.7,
        }}
      />
      <motion.div
        className="Asset__content"
        initial={{ height: 0 }}
        animate={{ height: inView ? height : 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
        }}
        style={{ width: assetWidth }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={asset.src}
            src={asset.src}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              type: 'spring',
              damping: 300,
            }}
            style={{ width: assetWidth, height }}
          ></motion.img>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default Asset;
