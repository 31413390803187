import React from 'react';

const WebGL = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M53.564 33.93h-.339v.883h-.296v-.882h-.34v-.255h.975v.255" />
    <path d="M53.697 33.676h.436l.196.758h.003l.196-.758h.436v1.137h-.278v-.863h-.004l-.238.863h-.227l-.239-.863h-.003v.863h-.278v-1.137" />
    <path d="M41.833 34.75a3.198 3.198 0 01-1.252.26c-.665 0-1.263-.115-1.794-.347a3.839 3.839 0 01-1.344-.956 4.23 4.23 0 01-.84-1.432 5.265 5.265 0 01-.291-1.773c0-.65.097-1.253.291-1.81a4.349 4.349 0 01.837-1.456c.364-.414.811-.739 1.342-.975.531-.235 1.129-.353 1.794-.353.446 0 .877.067 1.295.201.418.135.794.332 1.131.593.337.26.614.581.833.963.22.381.353.82.402 1.314h-1.824c-.114-.486-.333-.851-.657-1.095-.325-.243-.718-.364-1.18-.364-.43 0-.795.083-1.095.25-.3.166-.543.39-.73.67-.186.28-.322.599-.407.956a4.758 4.758 0 00-.127 1.109c0 .366.042.721.128 1.066.085.346.22.656.408.932.186.277.43.498.73.664.3.167.666.25 1.096.25.633 0 1.122-.16 1.467-.479.345-.32.546-.782.603-1.389h-1.921V30.12h3.643v4.696h-1.212l-.194-.985c-.341.438-.718.744-1.132.918" />
    <path d="M47.467 26.128v7.07h4.232v1.611h-6.137v-8.681h1.905" />
    <path d="M38.459 35.888c-2.915 1.773-7.28 2.782-12.15 2.782-8.775 0-15.889-3.654-15.889-8.161 0-4.508 7.114-8.161 15.889-8.161 4.888 0 9.27 1.027 12.185 2.81-3.181-2.988-8.885-5.088-15.407-5.089C13.1 20.068 5.001 24.74 5 30.506c0 5.765 8.096 10.44 18.084 10.442 6.496.001 12.185-2.092 15.375-5.06" />
    <path d="M22.451 25.452L20.776 32.7l-2.022-7.248h-1.536L15.2 32.7l-1.68-7.248h-1.582l2.485 9.447h1.485l2.09-7.42 2.066 7.42h1.489l2.573-9.447H22.45" />
    <path d="M28.31 29.429a2.327 2.327 0 00-.864-.75 2.694 2.694 0 00-1.21-.262c-.508 0-.966.092-1.36.274a2.548 2.548 0 00-.95.753c-.474.606-.725 1.46-.725 2.47 0 .45.064.87.19 1.253.126.384.313.722.555 1.003.508.59 1.232.902 2.095.902.452 0 .822-.047 1.13-.144.269-.086.447-.196.594-.292.188-.125.361-.278.515-.455.13-.15.245-.318.344-.498.176-.324.261-.626.28-.787l.014-.106h-1.234l-.007.087c-.031.401-.557 1.117-1.495 1.117-1.34 0-1.659-.969-1.68-1.822h4.486v-.094c0-.55-.056-1.053-.167-1.493a3.432 3.432 0 00-.51-1.156zm-2.215.1c.993 0 1.525.536 1.622 1.635h-3.214c.047-.895.757-1.634 1.592-1.634" />
    <path d="M35.198 30.337a3.01 3.01 0 00-.524-1 2.417 2.417 0 00-.847-.672 2.633 2.633 0 00-1.138-.244h-.002c-.382.006-.73.09-1.035.247-.253.13-.48.314-.676.546v-2.898h-1.228v8.585h1.17v-.584c.102.134.234.276.409.401.332.238.742.358 1.22.358.514 0 .972-.114 1.361-.34.348-.202.642-.492.875-.863.203-.324.357-.705.459-1.134.088-.37.134-.775.134-1.172 0-.438-.06-.852-.178-1.23zm-1.105 1.394c0 .674-.135 1.239-.392 1.634-.273.42-.67.633-1.178.633-.735 0-1.594-.514-1.594-1.963 0-.694.086-1.228.263-1.633.254-.584.682-.868 1.308-.868.665 0 1.11.26 1.36.797.207.444.233.981.233 1.4" />
  </svg>
);

export default WebGL;
