import React from 'react';

const Sketch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M15.887 8.487L29.95 7l14.062 1.487L54.9 23.102 29.95 52.155 5 23.102z"
      fillRule="nonzero"
    />
  </svg>
);

export default Sketch;
