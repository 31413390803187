import React from 'react';

import { GoMarkGithub } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';

import './SocialLinks.css';

export interface SocialLink {
  icon: 'github' | 'linkedin';
  label: string;
  url: string;
}

interface SocialLinksProps {
  links: SocialLink[];
}

const iconMap = {
  github: GoMarkGithub,
  linkedin: FaLinkedin,
};

const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
  return (
    <div className="SocialLinks">
      {links.map((link) => {
        const Icon = iconMap[link.icon];
        return (
          <a
            key={link.url}
            className="SocialLinks__link"
            href={link.url}
            aria-label={link.label}
          >
            <Icon />
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
