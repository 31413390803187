import React from 'react';

import Showcase, { ShowcaseProps } from './Showcase';
import { ContextProvider } from './Context';

const ShowcaseContainer: React.FC<ShowcaseProps> = (props) => {
  return (
    <ContextProvider>
      <Showcase {...props} />
    </ContextProvider>
  );
};

export default ShowcaseContainer;
