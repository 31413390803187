import React, { useRef, useState, useEffect } from 'react';
import { motion, AnimatePresence, useSpring } from 'framer-motion';
import useMeasure from 'use-measure';

import './Letter.css';

interface LetterProps {
  children: string;
}

const alphabet = ' \u00a0ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

const Letter: React.FC<LetterProps> = ({ children }) => {
  const [output, setOutput] = useState<string>(children);
  const nodeRef = useRef<HTMLDivElement>(null);
  const spring = useSpring(alphabet.indexOf(children), { damping: 300 });
  const { width } = useMeasure(nodeRef);

  useEffect(() => {
    spring.onChange((value) => setOutput(alphabet[Math.round(value)]));
    spring.set(alphabet.indexOf(children));
  }, [children]);

  return (
    <motion.div
      className="Letter"
      animate={{ width }}
      transition={{ damping: 300, delayChildren: 0.5 }}
    >
      <span className="Letter__content" ref={nodeRef}>
        {children === ' ' ? '\u00a0' : children}
      </span>

      <div className="Letter__inner">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{
              opacity: 0,
              y: '-50%',
            }}
            transition={{
              type: 'spring',
              damping: 300,
            }}
          >
            {children === ' ' ? '\u00a0' : output}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default Letter;
