import { Link } from 'gatsby';
import React from 'react';

interface NavItemProps {
  label: string;
  url: string;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ label, url, onClick }) => (
  <li>
    <Link to={url} onClick={onClick}>
      <span>{label}</span>
    </Link>
  </li>
);

export default NavItem;
