import React from 'react';

const Elixir = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M31.538 5c.093 4.139.031 10.276 4.652 15.867 4.62 5.59 10.463 8.405 10.463 17.074 0 11.3-8.51 17.059-15.96 17.059C23.24 55 14 50.206 14 36.84c0-7.456 3.627-16.83 10.263-25.122C26.382 9.07 28.939 6.24 31.538 5zM21.189 49.557c2.135 1.75 4.771 2.957 7.91 3.62 4.71.994 2.993-1.377 0-2.407-1.994-.688-4.63-1.092-7.91-1.213z"
      fillRule="nonzero"
    />
  </svg>
);

export default Elixir;
