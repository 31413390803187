import { graphql, StaticQuery, useStaticQuery } from 'gatsby';
import React from 'react';

import Navigation from './Navigation';

const NavContainer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query navigation {
      markdownRemark(
        fileAbsolutePath: { glob: "**/data/navigation/navigation.md" }
      ) {
        frontmatter {
          navLinks {
            label
            url
          }
        }
      }
    }
  `);
  return <Navigation links={data.markdownRemark.frontmatter.navLinks} />;
};

export default NavContainer;
