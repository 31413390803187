import React from 'react';

const Python = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M10 42.5a5 5 0 01-5-5v-15a5 5 0 015-5h7.5v-.125H30v-1H17.5V15c0-5.523 4.477-10 10-10h5c5.523 0 10 4.477 10 10v2.5h.125l-.005.821c-.003.268-.008.528-.014.783l-.025.744-.016.358-.04.69a33.72 33.72 0 01-.024.33l-.055.636c-.326 3.3-1.113 5.14-2.51 6.174l-.1.073-.207.14-.211.13-.219.122a5.29 5.29 0 01-.112.058l-.234.108a5.89 5.89 0 01-.502.192l-.274.084-.29.077c-.1.025-.203.048-.31.07l-.33.063-.352.057-.377.05-.197.023-.416.042-.678.053-.49.029-.524.024-.559.02-1.228.029-1.388.015-1.965.005-1.49.009-1 .016-.91.025-.825.037-.506.031-.474.038-.225.021-.429.048-.4.055-.377.062-.179.035-.341.075a6.753 6.753 0 00-1.183.393l-.26.124a5.73 5.73 0 00-.493.282l-.236.16-.233.17-.115.091c-1.487 1.19-2.329 3.153-2.697 6.445l-.065.654c-.01.112-.02.225-.028.34l-.049.705c-.014.24-.026.488-.036.74l-.027.777-.009.403-.01.833-.001.431H10zm26.875 3.938a2.312 2.312 0 100 4.624 2.312 2.312 0 000-4.624zm-13.75-37.5a2.312 2.312 0 100 4.624 2.312 2.312 0 000-4.624zM50 17.5a5 5 0 015 5v15a5 5 0 01-5 5h-7.5v.125H30v1h12.5V45c0 5.523-4.477 10-10 10h-5c-5.523 0-10-4.477-10-10v-2.5h-.125l.005-.814.014-.776.024-.74c.004-.12.01-.238.015-.356l.039-.685.023-.33.053-.635c.314-3.293 1.067-5.166 2.374-6.211.067-.054.134-.106.203-.155l.208-.145.215-.133a5.238 5.238 0 011.248-.52l.298-.078.157-.035.33-.066.174-.03.367-.054.194-.025.408-.044.669-.055.486-.03.522-.024.558-.02.911-.021 1.006-.013 3.824-.016 1.02-.015.93-.024.849-.033.523-.028.492-.033.462-.039.433-.044.407-.05.194-.029.371-.06.35-.068.328-.075.311-.083.295-.09c.19-.064.372-.133.546-.208l.256-.118.247-.127.12-.067.237-.143c.04-.024.078-.05.117-.075l.23-.158.114-.083c1.755-1.3 2.651-3.527 2.963-7.494l.047-.697c.021-.357.038-.728.051-1.112l.02-.786.007-.407.005-.843H50z"
      fillRule="nonzero"
    />
  </svg>
);

export default Python;
