import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import useMeasure from 'use-measure';
import { motion } from 'framer-motion';

import './Diagonal.css';

interface DiagonalProps {
  size?: number;
}

const Diagonal: React.FC<DiagonalProps> = ({ size = 14 }) => {
  const [data, setData] = useState<any[]>([]);
  const nodeRef = useRef<HTMLDivElement>(null);
  const { width, height } = useMeasure(nodeRef);
  useEffect(() => {
    setData([...Array(Math.floor((width / size) * 2))]);
  }, [width, height]);

  return (
    <div className="Diagonal" ref={nodeRef}>
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        {data.map((line, index) => (
          <rect
            key={index}
            x={index * size * 2 - width + size * 2}
            y={-height}
            width={size / 1.5}
            height={height * 2}
            transform={`rotate(45 ${
              index * size * 2 - width + size + size / 2
            } ${height * 2 + -height})`}
          />
        ))}
      </svg>
    </div>
  );
};

export default Diagonal;
