import React from 'react';

import Anchor from '../Anchor';
import Button from '../Button';
import Card from '../Card';

import './Contact.css';

const Contact: React.FC = () => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.location.href = 'mailto:g.r.cozens@gmail.com';
  };
  return (
    <div className="Contact -hot" id="contact">
      <div className="Container">
        <Anchor label="STATUS - SEEKING" />
        <Card className="Contact__container" border={false}>
          <h2>Hit me up</h2>
          <p>Always open to interesting convos.</p>
          <Button href="mailto:haha@nicetry.com" onClick={onClick} size="large">
            Holla at your boy
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default Contact;
