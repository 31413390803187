import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import Skill, { SkillProps } from './Skill';
import './Skills.css';

interface SkillsProps {
  skills: SkillProps[];
}

const Skills: React.FC<SkillsProps> = ({ skills }) => {
  const availableSkills = useRef<SkillProps[]>([...skills]);

  const getNextSkills = () => {
    const show: SkillProps[] = [];
    for (let i = 0; i < 9; i += 1) {
      if (availableSkills.current.length === 0) {
        availableSkills.current = [...skills];
      }
      const add = availableSkills.current.shift();
      if (add) {
        show.push(add);
      }
    }
    return show;
  };

  const [visibleSkills, setVisibleSkills] = useState(getNextSkills());

  const request = useRef<number>(0);
  const lastUpdate = useRef<number>();

  const update: FrameRequestCallback = (time) => {
    if (!lastUpdate.current) {
      lastUpdate.current = time;
    }
    if (time - lastUpdate.current > 6000) {
      setVisibleSkills(getNextSkills());
      lastUpdate.current = time;
    }
    request.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    request.current = requestAnimationFrame(update);
    () => cancelAnimationFrame(request.current);
  }, []);

  return (
    <div className="Skills">
      <AnimatePresence exitBeforeEnter>
        {visibleSkills.map((skill, index) => (
          <Skill key={index} {...skill} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Skills;
