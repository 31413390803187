import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import './Button.css';

interface ButtonProps {
  className?: string;
  variant?: 'primary' | 'secondary';
  size?: 'normal' | 'large';
  icon?: React.ReactElement;
  to?: string;
  href?: string;
  onClick?: (event: React.SyntheticEvent) => void;
}

const Button: React.FC<ButtonProps> = ({
  to,
  href,
  variant = 'primary',
  size = 'normal',
  children,
  icon,
  className,
  ...props
}) => {
  const classes = cn(
    'Button',
    `-${variant}`,
    `-${size}`,
    { '-icon': !!icon },
    className
  );

  const content = icon ? (
    <>
      {icon}
      {children}
    </>
  ) : (
    children
  );

  if (href) {
    return (
      <a className={classes} href={href} target="_blank" {...props}>
        {content}
      </a>
    );
  }

  if (to) {
    return (
      <Link className={classes} to={to} {...props}>
        {content}
      </Link>
    );
  }

  return (
    <button className={classes} {...props}>
      {content}
    </button>
  );
};

export default Button;
