import React from 'react';

import './Anchor.css';

interface AnchorProps {
  label: string;
}

const Anchor: React.FC<AnchorProps> = ({ label }) => (
  <p className="Anchor">[ {label} ]</p>
);

export default Anchor;
