import React, { createContext, useCallback, useReducer, Dispatch } from 'react';

export interface ShowcaseState {
  activeIndex: number;
  direction: number;
  width: number;
  size: 'small' | 'medium' | 'large';
  modal: boolean;
}

export type ShowcaseAction =
  | {
      type: 'SET_ACTIVE_ITEM';
      activeIndex: number;
      direction: number;
    }
  | {
      type: 'SET_STAGE_DIMENSIONS';
      width: number;
    }
  | {
      type: 'SET_MODAL';
      modal: boolean;
    };

const initialState: ShowcaseState = {
  activeIndex: 0,
  direction: 1,
  width: 0,
  size: 'large',
  modal: false,
};

export const Context = createContext<{
  state: ShowcaseState;
  dispatch: Dispatch<ShowcaseAction>;
}>({ state: initialState, dispatch: () => {} });

const reducer = (
  state: ShowcaseState,
  action: ShowcaseAction
): ShowcaseState => {
  switch (action.type) {
    case 'SET_ACTIVE_ITEM':
      return {
        ...state,
        direction: action.direction,
        activeIndex: action.activeIndex,
      };
    case 'SET_STAGE_DIMENSIONS':
      return {
        ...state,
        width: action.width,
        size:
          action.width > 1023
            ? 'large'
            : action.width > 639
            ? 'medium'
            : 'small',
      };
    case 'SET_MODAL':
      return {
        ...state,
        modal: action.modal,
      };
    default:
      return initialState;
  }
};

export const ContextProvider: React.FC = (props) => {
  const memoReducer = useCallback(reducer, []);
  const [state, dispatch] = useReducer(memoReducer, initialState);
  return <Context.Provider value={{ state, dispatch }} {...props} />;
};
