import cn from 'classnames';
import React from 'react';

import './Card.css';

export interface CardProps {
  border?: boolean;
  color?: 'red' | 'purple';
  className?: string;
}

const Card: React.FC<CardProps> = ({
  border = true,
  color = 'red',
  children,
  className,
}) => (
  <div
    className={cn(
      'Card',
      {
        [`-${color}`]: !!color,
        '-border': !!border,
      },
      className
    )}
  >
    {children}
  </div>
);

export default Card;
