import React from 'react';

const Docker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M38.429 24.429V29h-5.143v-4.571h5.143zm-6.286 0V29H27v-4.571h5.143zm-6 0V29H21v-4.571h5.143zm-6 0V29H15v-4.571h5.143zm-6.286 0V29H8.714v-4.571h5.143zm6.286-5.715v4.572H15v-4.572h5.143zm6 0v4.572H21v-4.572h5.143zm6 0v4.572H27v-4.572h5.143zm0-5.714v4.571H27V13h5.143z" />
    <path d="M55 26.92s-2.22-2.107-6.782-1.364c-.493-3.593-4.316-5.699-4.316-5.699s-3.576 4.336-.987 9.168c-.74.372-1.973.868-3.822.868H5.18c-.616 2.354-.616 17.964 16.401 17.964 12.208 0 21.333-5.699 25.65-16.106 6.412.496 7.768-4.832 7.768-4.832" />
  </svg>
);

export default Docker;
